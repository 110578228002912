import React from "react";
import { Link } from "gatsby";

import SEO from "react-seo-component";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

export const Layout = ({
  children,
  pageTitle,
  pageDescription,
  titleSeparator,
  isArticle,
  publishedDate,
  modifiedDate,
  path,
  featuredImage,
}) => {
  const {
    siteTitle,
    description,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata();

  return (
    <>
      <SEO
        title={pageTitle}
        titleTemplate={siteTitle}
        description={pageDescription ? pageDescription : description}
        image={featuredImage ? `${siteUrl}${featuredImage}` : `${siteUrl}${image}`}
        pathname={`${siteUrl}${path}`}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        titleSeparator={titleSeparator ? titleSeparator : null}
        author={authorName}
        article={isArticle ? isArticle : false}
        publishedDate={publishedDate ? publishedDate : null}
        modifiedDate={modifiedDate ? modifiedDate : null}
      />
      <div className="site-wrapper">
        <header>
          <div className="header-content container">
            <Navbar />
          </div>
        </header>

        <main className="content container small">{children}</main>

        <Footer />
      </div>
    </>
  );
};
